import { requestToken, accesstToken, getUserById, mintTwitterNFT, createTweet, getUsernameById,
  getNFTWalletSignature,
  getTwitterNFTSignature
} from '../../api'
import router from '../../router'
import { saveOauth, getOauth, removeOauth } from '../../utils'

import nftWalletABI from '../../constants/nftWallet.json'
import twitterNFTABI from '../../constants/twitterNFT.json'
import { ethers } from 'ethers'
import { TWEET_CONTENT } from '../../constants'

const namespaced = true

const state = {
  profile: {
    id: '',
    username: '',
    profile_image_url: '',
    description: '',
    created_at: ''
    // "name": "hehe",
    // "profile_image_url": "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
    // "username": "hehe2022meter",
    // "id": "1587602065974431745",
    // "description": "",
    // "created_at": "2022-11-02T00:26:57.000Z"
  },
  loginStatus: false,
  requestTokenIng: false,
  mintLoading: false,
  deployLoading: false,
  updateLoading: false,
  mintedUsername: ''
}

const getters = {}

const mutations = {
  setProfile(state, profile) {
    state.profile = { ...state.profile, ...profile }
  },
  clearProfile(state) {
    state.profile = {
      id: '',
      username: '',
      profile_image_url: '',
      description: ''
    }
  },
  setLoginStatus(state, loginStatus) {
    state.loginStatus = loginStatus
  },
  setRequestTokenIng(state, requestTokenIng) {
    state.requestTokenIng = requestTokenIng
  },
  setMintLoading(state, mintLoading) {
    state.mintLoading = mintLoading
  },
  setDeployLoading(state, deployLoading) {
    state.deployLoading = deployLoading
  },
  setMintedUsername(state, name) {
    state.mintedUsername = name
  },
  setUpdateLoading(state, updateLoading) {
    state.updateLoading = updateLoading
  }
}

const actions = {
  async requestToken({ rootState, state, commit }) {
    if (state.loginStatus) {
      console.log('already login')
      return
    }
    commit('setRequestTokenIng', true)
    const baseUrl = rootState.wallet.currentNetwork.apiBase
    console.log('baseUrl', baseUrl)
    if (!baseUrl) return
    const result = await requestToken(baseUrl)
    if (!result.error) {
      return result.data.authorizeURL
    } else {
      console.log('requestToken', result.message)
    }
    commit('setRequestTokenIng', false)
  },
  async accessToken({ rootState }, { oauth_token, oauth_verifier }) {
    const baseUrl = rootState.wallet.currentNetwork.apiBase
    console.log('baseUrl', baseUrl)
    if (!baseUrl) return

    const result = await accesstToken(baseUrl, oauth_token, oauth_verifier)
    if (!result.error) {
      const { oauth_token, oauth_token_secret, user_id, screen_name } = result.data
      saveOauth({ oauth_token, oauth_token_secret, user_id, screen_name })
      router.push({ query: {} })
    } else {
      console.log('accessToken', result.message)
    }
  },
  async getUserById({ rootState, commit, dispatch }) {
    const baseUrl = rootState.wallet.currentNetwork.apiBase
    console.log('baseUrl', baseUrl)
    if (!baseUrl) return

    const { oauth_token, oauth_token_secret, user_id } = getOauth()
    // const { oauth_token, oauth_token_secret, user_id } = {
    //   oauth_token: '1587602065974431745-IbeaBdV6rbo5i9yTI95oLrOzaF5Y1B',
    //   oauth_token_secret: 'FZreX0o0Mg6btNTkTTeC96Xp1QEsEPu1V6EAOL1B1khNC',
    //   user_id: '1587602065974431745'
    // }

    if (!oauth_token || !oauth_token_secret || !user_id) {
      console.warn('absent token or secret or id')
      return
    }

    const result = await getUserById(baseUrl, oauth_token, oauth_token_secret, user_id)

    if (!result.error) {
      commit('setProfile', result.data)
      commit('setLoginStatus', true)
      dispatch('getUsernameById', { id: result.data.id })
    } else {
      console.log('getUserById', result.message)
      commit('setLoginStatus', false)
    }
  },
  async login({ dispatch }, { oauth_token, oauth_verifier }) {
    await dispatch('accessToken', { oauth_token, oauth_verifier })
    await dispatch('getUserById')
  },
  logout({ commit }) {
    removeOauth()
    commit('setLoginStatus', false)
    commit('clearProfile')
  },
  async mintTwitterNFT({ rootState, commit }, { address, user_id, username }) {
    const baseUrl = rootState.wallet.currentNetwork.apiBase
    console.log('baseUrl', baseUrl)
    if (!baseUrl) return
    commit('setMintLoading', true)
    const result = await mintTwitterNFT(baseUrl, address, user_id, username)
    if (!result.error) {
      if (result.data.error) {
        commit('setMintLoading', false)
        alert(`${result.data.error.reason},${result.data.error.error.reason}`)
      }
      commit('setMintLoading', false)
      return result.data.transactionHash
    } else {
      commit('setMintLoading', false)
      console.log('mint twitter nft error', result.message)
    }
  },
  async createTweet({ rootState }) {
    const baseUrl = rootState.wallet.currentNetwork.apiBase
    console.log('baseUrl', baseUrl)
    if (!baseUrl) return

    const { oauth_token, oauth_token_secret, user_id } = getOauth()
    // const { oauth_token, oauth_token_secret, user_id } = {
    //   oauth_token: '1587602065974431745-IbeaBdV6rbo5i9yTI95oLrOzaF5Y1B',
    //   oauth_token_secret: 'FZreX0o0Mg6btNTkTTeC96Xp1QEsEPu1V6EAOL1B1khNC',
    //   user_id: '1587602065974431745'
    // }

    const result = await createTweet(baseUrl, oauth_token, oauth_token_secret, TWEET_CONTENT)
    if (!result.error) {
      console.log(result.data)
      if (result.data.error) {
        alert(result.data.error.detail)
      } else
      if (result.data.data.id) {
        return true
      }
    } else {
      console.log(result.message)
    }
  },
  async getUsernameById({ rootState, commit }, { id }) {
    // const baseUrl = rootState.wallet.currentNetwork.apiBase
    // const result = await getUsernameById(baseUrl, id)
    // if (result.data) {
    //   commit('setMintedUsername', result.data.result)
    //   return result.data.result
    // }
    const { currentNetwork, web3Provider } = rootState.wallet
    const contract = new ethers.Contract(currentNetwork.twitterContract, twitterNFTABI, web3Provider)
    const username = await contract.username(id)
    console.log('username', username)
    if (username) {
      commit('setMintedUsername', username)
    }
  },
  async updateUsername({ rootState, commit }) {
    try {
      const { currentNetwork, signer, account, chainId } = rootState.wallet
      const { id, username } = state.profile
      commit('setUpdateLoading', true)
      const result = await getTwitterNFTSignature(currentNetwork.apiBase, account, id, username, chainId)
      if (!result.error) {
        const signature = result.data.result
        console.log('signature', signature)
        const signContract = new ethers.Contract(currentNetwork.twitterContract, twitterNFTABI, signer)
        const tx = await signContract.setUsername(id, username, signature)
        console.log('tx', tx)
        const receipt = await tx.wait()
        console.log('receit', receipt)
        commit('setUpdateLoading', false)
        return receipt.transactionHash
      }
    } catch(e) {
      console.log('update username error', e)
      alert(`${e.data.message}`)
      commit('setUpdateLoading', false)
    }
  },
  async deployNFTWallet({ rootState, state, commit }) {
    try {
      const { web3Provider, signer, currentNetwork, account, chainId } = rootState.wallet
      const { id } = state.profile
      commit('setDeployLoading', true)
      // const contract = new ethers.Contract(currentNetwork.nftWallet, nftWalletABI, web3Provider)
      // const addr = await contract.getAddress(state.profile.id)
      // console.log('addr', addr)
      const result = await getNFTWalletSignature(currentNetwork.apiBase, account, id, chainId)
      if (!result.error) {
        const signature = result.data.result
        console.log('signature', signature)
        const signContract = new ethers.Contract(currentNetwork.nftWallet, nftWalletABI, signer)
        const tx = await signContract.deploy(account, id, signature)
        console.log('tx', tx)
        const receipt = await tx.wait()
        console.log('receit', receipt)
        commit('setDeployLoading', false)
        return receipt.transactionHash
      }
    } catch(e) {
      console.log('deploy nft wallet error', e)
      alert(`${e.data.message},only deploy once.`)
      commit('setDeployLoading', false)
    }
  }
}

export const twitter = { namespaced, state, getters, mutations, actions }
