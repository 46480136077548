import axios from 'axios'

import { supportNetworkList, passportMainPreUrl, passportTestPreUrl } from '@/constants'
import walletTokens from '@/wallet-tokens.json'
import { ethers } from 'ethers'
export * from './domainname'

export const getWalletTokens = async () => {
  try {
    return await axios.get('https://raw.githubusercontent.com/meterio/token-list/master/generated/wallet-tokens.json')
  } catch (e) {
    console.log('get wallet tokens error: ', e)
    return { data: walletTokens }
  }
}

export const getTokenPrice = async (coinId) => {
  const { data } = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${coinId}&vs_currencies=usd`)

  return data[coinId]['usd']
}

export const getAllTokenPrice = async (coinIds) => {
  const FTBIndex = coinIds.indexOf('FTB')
  if (FTBIndex !== -1) {
    coinIds.splice(FTBIndex, 1)
  }
  const MENIndex = coinIds.indexOf('MEN')
  if (MENIndex !== -1) {
    coinIds.splice(MENIndex, 1)
  }
  console.log('coinIds', coinIds)
  let priceData = {}
  try {
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coinIds.join(',')}&vs_currencies=${'usd,'.repeat(
        coinIds.length,
      )}`,
    )
    priceData = { ...data }

    if (priceData.meter && FTBIndex !== -1) {
      const ftb_mtrg_pair = "0x88cdb3e764dedcc2e3a1642957ebd513765b252a"
      const ftbPrice = await getTokenPriceByPair(ftb_mtrg_pair, "FTB", priceData.meter.usd)

      priceData = {
        ...priceData,
        ...ftbPrice
      }
    }

    if (priceData.meter && MENIndex !== -1) {
      const pair = "0x2d4274c5e4a8d75c736493fc2c8598a12e5dea28"
      const price = await getTokenPriceByPair(pair, "MEN", priceData.meter.usd)

      priceData = {
        ...priceData,
        ...price,
        // suUSD: { usd: 1 },
        // suETH: priceData.ethereum,
        // suBTC: priceData['wrapped-bitcoin']
      }
    }

    console.log('price data', priceData)
    return priceData
  } catch (e) {
    console.log('get token price error', e)
    return priceData
  }
}

const getTokenPriceByPair = async (pairAddr, aimSymbol, mtrgPrice) => {
  const res = await axios.post('https://graph-meter.voltswap.finance/subgraphs/name/meterio/voltswapv2-subgraph', {
    query: `query {
      pair(id: "${pairAddr}") {
        token0 {
          symbol,
          derivedETH
        },
        token1 {
          symbol,
          derivedETH
        }
      }
    }`,
  })
  const aimPair = res.data.data.pair
  let aimDerivedETH = 0
  if (aimPair.token0.symbol === aimSymbol) {
    aimDerivedETH = aimPair.token0.derivedETH
  } else {
    aimDerivedETH = aimPair.token1.derivedETH
  }
  return { [aimSymbol]: { usd: mtrgPrice * Number(aimDerivedETH) } }
}

export const swap = async (url, params) => {
  try {
    const query = []
    for (const p in params) {
      query.push(`${p}=${params[p]}`)
    }
    const res = await axios.get(`${url}swap/swapGasV2?${query.join('&')}`)
    return {
      error: false,
      receipt: res.data.receipt,
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}

// -----------------------------------------------------------------------------------------------

export const getCurrentNetwork = (chainId) => {
  return supportNetworkList.find((network) => network.networkId === Number(chainId)) || {}
}

export const getSupportNetworkListByMode = (mode) => {
  return supportNetworkList.filter((network) => {
    if (String(mode) === 'mainnet') {
      return network.mode === mode
    } else {
      return true
    }
  })
}

export const getPassportPreUrl = (mode) => {
  if (mode === 'mainnet') {
    return passportMainPreUrl
  } else {
    return passportTestPreUrl
  }
}

export const getSignature = async (
  name,
  version,
  signer,
  verifyingContract,
  owner,
  spender,
  value,
  nonce,
  deadline,
  chainId,
) => {
  let signature = await signer._signTypedData(
    { name, version, chainId, verifyingContract },
    {
      Permit: [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
      ],
    },
    { owner: owner, spender: spender, value: value, nonce: nonce, deadline: deadline },
  )
  if (signature.endsWith('00') || signature.endsWith('01')) {
    const { r, s, v } = ethers.utils.splitSignature(signature)
    const reconstructedSignature = ethers.utils.joinSignature({ r, s, v })
    return reconstructedSignature
  }
  return signature
}

export const getNFTs = async (url, { address, page, limit }) => {
  try {
    const res = await axios.get(`${url}accounts/${address}/nfts?page=${page}&limit=${limit}`)
    return {
      error: false,
      data: res.data,
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}

export const getMeterBalance = async (address) => {
  try {
    const res = await axios.get(`https://mainnet.meter.io/accounts/${address}`)
    if (res && res.data) {
      return res.data
    }
  } catch (e) {
    return
  }
}

/***************************************** twitter ************************************************/

export const requestToken = async (baseUrl) => {
  try {
    const res = await axios.get(`${baseUrl}twitter/requestToken`)
    if (res && res.data) {
      return {
        error: false,
        data: res.data,
      }
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}

export const accesstToken = async (baseUrl, oauth_token, oauth_verifier) => {
  try {
    const res = await axios.get(`${baseUrl}twitter/accessToken/${oauth_token}/${oauth_verifier}`)
    if (res && res.data) {
      return {
        error: false,
        data: res.data,
      }
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}

export const getUserById = async (baseUrl, oauth_token, oauth_token_secret, userId) => {
  try {
    const res = await axios.get(`${baseUrl}twitter/user/${oauth_token}/${oauth_token_secret}/${userId}`)
    if (res && res.data) {
      return {
        error: false,
        data: res.data.data,
      }
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}

export const mintTwitterNFT = async (baseUrl, address, user_id, username) => {
  try {
    const res = await axios.get(`${baseUrl}twitter/mint/${address}/${user_id}/${username}`)
    console.log('mint res', res)
    if (res && res.data) {
      return {
        error: false,
        data: res.data,
      }
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}

export const createTweet = async (baseUrl, oauth_token, oauth_token_secret, text) => {
  try {
    const res = await axios.get(`${baseUrl}twitter/create/${oauth_token}/${oauth_token_secret}?text=${text}`)
    console.log('create tweet res', res)
    if (res && res.data) {
      return {
        error: false,
        data: res.data,
      }
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}

export const getNFTWalletSignature = async (baseUrl, account, userId, chainId) => {
  try {
    const res = await axios.get(`${baseUrl}twitter/sinatureNFTWallet/${account}/${userId}/${chainId}`)
    console.log('nft wallet signature', res)
    if (res && res.data) {
      return {
        error: false,
        data: res.data,
      }
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}

export const getTwitterNFTSignature = async (baseUrl, account, userId, username, chainId) => {
  try {
    const res = await axios.get(`${baseUrl}twitter/sinatureTwitter/${account}/${userId}/${username}/${chainId}`)
    console.log('twitter signature', res)
    if (res && res.data) {
      return {
        error: false,
        data: res.data,
      }
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}

export const getUsernameById = async (baseUrl, id) => {
  try {
    const res = await axios.get(`${baseUrl}twitter/username/${id}`)
    console.log('username', res)
    if (res && res.data) {
      return {
        error: false,
        data: res.data,
      }
    }
  } catch (e) {
    return {
      error: true,
      message: e.message,
    }
  }
}
