import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/layout'),
    children: [
      {
        path: '',
        name: 'wallet',
        component: () => import('@/views/Wallet'),
      },
      {
        path: 'swap',
        name: 'swap',
        component: () => import('@/views/SwapGas'),
      },
      {
        path: 'nft',
        name: 'nft',
        component: () => import('@/views/NFT')
      },
      {
        path: 'sumerian',
        name: 'sumerian',
        component: () => import('@/views/Sumerian')
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
