import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'

import erc20 from '@/constants/erc20.json'

const namespaced = true

const state = {
  showCheckModal: {
    show: false,
    token: {},
  },
  allowances: [],
  contract: null,
  getAllowancesLoading: false,
  approvalLoading: {},
  breakFlag: false,
}

const getters = {}

const mutations = {
  setShowCheckModal(state, { token = {} } = {}) {
    state.showCheckModal = {
      show: !state.showCheckModal.show,
      token,
    }
  },
  setAllowances(state, allowances) {
    state.allowances = state.allowances.concat(allowances)
  },
  clearAllowances(state) {
    state.allowances = []
  },
  setContract(state, contract) {
    state.contract = contract
  },
  setGetAllowancesLoading(state, loading) {
    state.getAllowancesLoading = loading
  },
  setApproveLoading(state, { spender, status }) {
    state.approvalLoading = Object.assign(
      {},
      {
        ...state.approvalLoading,
      },
      {
        [spender]: status,
      },
    )
  },
  setBreakFlag(state, flag) {
    state.breakFlag = flag
  },
}

const actions = {
  async getAllowance({ state, rootState, commit }) {
    try {
      commit('clearAllowances')
      commit('setBreakFlag', false)
      commit('setGetAllowancesLoading', true)
      const { chainId, account, web3Provider } = rootState.wallet
      const { token } = state.showCheckModal
      const contract = new ethers.Contract(token.address, erc20, web3Provider)
      // const contract = new ethers.Contract(token.address, erc20, new ethers.providers.JsonRpcProvider('https://rpc.meter.io'))
      commit('setContract', contract)

      const spenders = []
      if ([361, 56].includes(chainId)) {
        let startBlock = 1
        let endBlock = await web3Provider.getBlockNumber()
        console.log('best blocknumber', endBlock)
        for (;;) {
          if (state.breakFlag) {
            return
          }
          if (endBlock < startBlock) {
            console.log('break')
            break
          }
          const preBlock = endBlock - 4999
          console.log(preBlock <= startBlock ? startBlock : preBlock, endBlock)
          const event = contract.filters.Approval(account, null, null)
          const approvals = await contract.queryFilter(event, preBlock <= startBlock ? startBlock : preBlock, endBlock)
          console.log('approvals', approvals)
          for (let a of approvals) {
            if (!spenders[a.args._spender]) {
              const spender = a.args._spender
              spenders[spender] = true
              const allowance = await contract.allowance(account, spender)
              const tempAllowance = new BigNumber(String(allowance)).div(`1e${token.decimals}`)
              commit('setAllowances', [
                {
                  spender,
                  allowance: tempAllowance.isGreaterThan('0xfffff') ? 'unlimited' : tempAllowance.toFormat(2),
                },
              ])
            }
          }

          endBlock = preBlock - 1
        }
      } else {
        // const account = '0x619c918f314f34a6f25b877ddeb8ca9fd077462e'
        const event = contract.filters.Approval(account, null, null)
        const approvals = await contract.queryFilter(event)
        for (let a of approvals) {
          spenders[a.args._spender] = true
        }
        const allowances = []
        for (let spender of Object.keys(spenders)) {
          if (state.breakFlag) {
            return
          }
          const allowance = await contract.allowance(account, spender)
          const tempAllowance = new BigNumber(String(allowance)).div(`1e${token.decimals}`)
          allowances.push({
            spender,
            allowance: tempAllowance.isGreaterThan('0xfffff') ? 'unlimited' : tempAllowance.toFormat(2),
          })
        }

        commit('setAllowances', allowances)
      }

      commit('setGetAllowancesLoading', false)
    } catch (e) {
      console.log(e)
      commit('setGetAllowancesLoading', false)
    }
  },
  async approval({ state, rootState, commit, dispatch }, { spender, value }) {
    try {
      commit('setApproveLoading', { spender, status: true })
      const { token } = state.showCheckModal
      const { signer } = rootState.wallet
      const contract = new ethers.Contract(token.address, erc20, signer)
      const tx = await contract.approve(spender, new BigNumber(value).times(`1e${token.decimals}`).toFixed())

      await tx.wait()

      commit('setApproveLoading', { spender, status: false })

      dispatch('updateAllowance', { spender })
    } catch (e) {
      commit('setApproveLoading', { spender, status: false })
    }
  },
  async updateAllowance({ state, rootState }, { spender }) {
    const { token } = state.showCheckModal
    const { account } = rootState.wallet
    const allowance = await state.contract.allowance(account, spender)
    const tempAllowance = new BigNumber(String(allowance)).div(`1e${token.decimals}`)
    const indx = state.allowances.findIndex((e) => e.spender === spender)
    state.allowances.splice(indx, 1, {
      spender,
      allowance: tempAllowance.isGreaterThan('0xfffff') ? 'unlimited' : tempAllowance.toFormat(2),
    })
  },
}

export const checkAllowance = { namespaced, state, getters, mutations, actions }
