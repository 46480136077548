export const getOauth = () => {
  const oauth_token = localStorage.getItem('meter_wallet_oauth_token')
  const oauth_token_secret = localStorage.getItem('meter_wallet_oauth_token_secret')
  const user_id = localStorage.getItem('meter_wallet_oauth_id')
  const screen_name = localStorage.getItem('meter_wallet_oauth_screen_name')

  return { oauth_token, oauth_token_secret, user_id, screen_name }
}

export const saveOauth = ({
  oauth_token,
  oauth_token_secret,
  user_id,
  screen_name
}) => {
  localStorage.setItem('meter_wallet_oauth_id', user_id)
  localStorage.setItem('meter_wallet_oauth_screen_name', screen_name)
  localStorage.setItem('meter_wallet_oauth_token', oauth_token)
  localStorage.setItem('meter_wallet_oauth_token_secret', oauth_token_secret)
}

export const removeOauth = () => {
  localStorage.removeItem('meter_wallet_oauth_id')
  localStorage.removeItem('meter_wallet_oauth_screen_name')
  localStorage.removeItem('meter_wallet_oauth_token')
  localStorage.removeItem('meter_wallet_oauth_token_secret')
}

export async function twitterSign(
  signer,
  verifyingContract,
  user,
  tokenId,
  _username,
  chainId
) {

  const name = "ERC721Twitter";
  const version = "1.0";
  let signature = await signer._signTypedData(
    { name, version, chainId, verifyingContract },
    {
      ERC721Twitter: [
        { name: "user", type: "address" },
        { name: "tokenId", type: "uint256" },
        { name: "_username", type: "string" }
      ],
    },
    {
      user: user,
      tokenId: tokenId,
      _username: _username
    }
  );
  return signature;
}

export async function walletSign(
  signer,
  verifyingContract,
  user,
  uid,
  chainId
) {
  const name = "NFTWallet";
  const version = "1.0";
  let signature = await signer._signTypedData(
    { name, version, chainId, verifyingContract },
    {
      NFTWallet: [
        { name: "user", type: "address" },
        { name: "uid", type: "uint256" }
      ],
    },
    {
      user: user,
      uid: uid
    }
  );
  return signature;
}