import { ethers } from 'ethers'
import { getCurrentNetwork } from '@/api'

const { id, hexZeroPad } = ethers.utils

const namespaced = true

const state = {}

const getters = {}

const mutations = {}

const actions = {
  watchERC20({ rootState, dispatch }) {
    console.log('start watch token balance change.')

    const chainId = rootState.wallet.chainId
    const account = rootState.wallet.account
    const currentNetwork = getCurrentNetwork(chainId)

    if (!currentNetwork) {
      return
    }

    if (currentNetwork.nativeTokenSymbol === 'MTR') {
      return
    }

    const provider = ethers.getDefaultProvider(currentNetwork.rpcUrl)

    provider.removeAllListeners()

    const topicSets1 = [id('Transfer(address,address,uint256)'), [hexZeroPad(account, 32)], null]
    const topicSets2 = [id('Transfer(address,address,uint256)'), null, [hexZeroPad(account, 32)]]

    provider.on(topicSets1, (log, event) => {
      console.log('token balance change: ', log, event)
      console.log('transactionHash', log.transactionHash)
      dispatch('getTxByTxhash', { transactionHash: log.transactionHash, address: log.address, provider })
    })

    provider.on(topicSets2, (log, event) => {
      console.log('token balance change: ', log, event)
      console.log('transactionHash', log.transactionHash)
      dispatch('getTxByTxhash', { transactionHash: log.transactionHash, address: log.address, provider })
    })
  },
  async getTxByTxhash({ rootState, dispatch }, { transactionHash, address, provider }) {
    if (!Object.values(rootState.tx.txHashs).includes(transactionHash)) {
      const token = rootState.token.tokens.find(
        (token) => token.address.toLocaleLowerCase() === address.toLocaleLowerCase(),
      )
      if (token) {
        const txRes = await provider.waitForTransaction(transactionHash)
        console.log('txRes: ', txRes)
        setTimeout(() => {
          dispatch('token/updateToken', { symbol: token.symbol }, { root: true })
        }, 3000)
      }
    }
  },
}

export const watchBalance = { namespaced, state, getters, mutations, actions }
