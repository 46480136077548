import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { wallet } from '@/store/modules/wallet'
import { token } from '@/store/modules/token'
import { tx } from '@/store/modules/tx'
import { watchBalance } from '@/store/modules/watchBalance'
import { checkAllowance } from '@/store/modules/checkAllowance'
import { swapGas } from './modules/swapGas'
import { twitter } from './modules/twitter'

export default new Vuex.Store({
  state: {
    showQRCodeModal: false,
    showLogoutModal: false,
    showConnectMetamaskModal: false,
    showTwitterModal: false,
    showMintTwitterNFTModal: false,

    headerUserOperationActive: false,
    showSelectNetForMobile: false,
    showWalletInfoForMobile: false,
  },
  mutations: {
    setShowQRCodeModal(state) {
      state.showQRCodeModal = !state.showQRCodeModal
    },
    setShowLogoutModal(state) {
      state.showLogoutModal = !state.showLogoutModal
    },
    setShowTwitterModal(state) {
      state.showTwitterModal = !state.showTwitterModal
    },
    setShowMintTwitterNFTModal(state) {
      state.showMintTwitterNFTModal = !state.showMintTwitterNFTModal
    },

    setHeaderUserOperationActive(state, status) {
      let _status;
      if (status !== undefined) {
        _status = status
      } else {
        _status = !state.headerUserOperationActive
      }
      state.headerUserOperationActive = _status
      if (_status) {
        state.showSelectNetForMobile = false
        state.showWalletInfoForMobile = false
      }
    },
    setShowSelectNetForMobile(state, status) {
      let _status;
      if (status !== undefined) {
        _status = status
      } else {
        _status = !state.showSelectNetForMobile
      }
      state.showSelectNetForMobile = _status
      if (_status) {
        state.headerUserOperationActive = false
        state.showWalletInfoForMobile = false
      }
    },
    setShowWalletInfoForMobile(state, status) {
      let _status;
      if (status !== undefined) {
        _status = status
      } else {
        _status = !state.showWalletInfoForMobile
      }
      state.showWalletInfoForMobile = _status
      if (_status) {
        state.headerUserOperationActive = false
        state.showSelectNetForMobile = false
      }
    }
  },
  actions: {},
  modules: {
    wallet,
    token,
    tx,
    watchBalance,
    checkAllowance,
    swapGas,
    twitter,
  },
})
