import { ethers } from 'ethers'
import domainjs from 'meterdomainjs'
import domainnameAbi from '../constants/domainname.json'

const rpcUrl = 'https://rpc.meter.io/'
// config for .mtr name service
const contactAddress1 = '0xc518477643732dfccdc78f4e8484cc6ee44900bb'
const config =
{
  testnet: {
    rpcUrl: "",
    contactAddress: ""
  },
  mainnet: {
    rpcUrl,
    contactAddress: contactAddress1
  },
  defaultNetwork: "mainnet"
}
const sdk = domainjs.SDK(config);

// for .meter nanme service
const contractAddress2 = '0x7fd85de6312bdbd8d4f625f7b80a254777c00b17'
const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
const contract = new ethers.Contract(contractAddress2, domainnameAbi, provider);

export const getAddressByDomainname = async (domainname) => {
  let status = 1
  let address = ''
  let msg = ''
  if (domainname.endsWith('.meter')) {
    const _name = domainname.substring(0, domainname.length - 6)
    const addr = await getDomainnamesMeter(_name)
    if (addr) {
      status = 1
      address = addr
    } else {
      status = 0
      msg = 'domainname not exists'
    }
  } else if (domainname.endsWith('.mtr') || domainname.endsWith('.mtrg')) {
    const addr = await getDomainnamesMtr(domainname)
    if (addr) {
      status = 1
      address = addr
    } else {
      status = 0
      msg = 'domainname not exists'
    }
  } else {
    status = 0
    msg = 'invalid domainname (xxx.meter or xxx.mtr/mtrg)'
  }

  return {
    status,
    address,
    msg
  }
}

export const getDomainnamesMeter = async (domainname) => {
  try {
    const address = await contract.getAddress(domainname)

    return address
  } catch (e) {
    return
  }
}

export const getDomainnamesMtr = async (domainname) => {
  try {
    const res = await sdk.getOwner(domainname);

    return res.owner
  } catch (e) {
    return
  }
}