import { ethers } from 'ethers'
import { getSignature, swap } from '../../api'
import BigNumber from 'bignumber.js'

import erc20 from '@/constants/erc20.json'
import { UNIT_WEI, gasSwapFee } from '@/constants'
import meterGovAbi from '@/constants/meterGovAbi.json'
import routerPermitAbi from '@/constants/routerPermitAbi.json'
import { getWalletTokens } from '../../api'

const swapToken = ['MTRG', 'MTR', 'ETH', 'BNB', 'suUSD', 'USDC.eth']

const namespaced = true

const state = {
  loading: false,
  pilotLoading: false,
  balanceLoading: false,
  tokenList: [],
}

const getters = {}

const mutations = {
  setLoading(state, loading) {
    state.loading = loading
  },
  setPilotLoading(state, pilotLoading) {
    state.pilotLoading = pilotLoading
  },
  setBalanceLoading(state, balanceLoading) {
    state.balanceLoading = balanceLoading
  },
  setTokenList(state, tokenList) {
    state.tokenList = tokenList
  },
}

const actions = {
  async getMeterBalance({ rootState, commit, dispatch }) {
    const chainId = rootState.wallet.chainId
    if (chainId !== 82) return
    commit('setBalanceLoading', true)
    const walletTokens = await getWalletTokens()

    const tokenList = []
    for (const token of walletTokens.data.tokens) {
      if (Number(chainId) === token.chainId && swapToken.includes(token.symbol)) {
        const t = await dispatch('getTokenBalance', { token })
        // console.log(t)
        tokenList.push(t)
      }
    }
    commit('setTokenList', tokenList)
    commit('setBalanceLoading', false)
  },
  async getTokenBalance({ rootState }, { token }) {
    try {
      const web3Provider = rootState.wallet.web3Provider
      const signer = rootState.wallet.signer
      const account = rootState.wallet.account

      let balance = 0
      if (token.native) {
        balance = await signer.getBalance(ethers.providers.balance)
      } else {
        const contract = new ethers.Contract(token.address, erc20, web3Provider)
        balance = await contract.balanceOf(account)
      }

      const _balance = new BigNumber(String(balance)).div(`1e${token.decimals}`)
      return {
        ...token,
        balance: _balance.toFixed(6),
      }
    } catch (e) {
      console.log('get swap token balance error', e)
      return []
    }
  },
  async actionSwapGas({ rootState, commit, dispatch }, { amount, fromToken }) {
    commit('setLoading', true)

    const { chainId, signer, account, web3Provider } = rootState.wallet
    const { routerAddr, apiBase, routes } = rootState.wallet.currentNetwork

    try {
      const tokenIn = fromToken.address
      const path = routes[fromToken.symbol.toLowerCase()]
      // const router = new ethers.Contract(routerAddr, routerPermitAbi, new ethers.Wallet('0x278e623d5c3445753374f065a7ad9c76d82a642e416ac670d7d90eafd4a34ad0', new ethers.providers.JsonRpcProvider(rpcUrl)))
      const token = new ethers.Contract(tokenIn, meterGovAbi, signer)
      // let routerAddr = routerAddrs[fromToken.symbol.toLowerCase()]
      let name = ''
      let version = ''
      if (fromToken.symbol === 'MTRG') {
        name = 'MeterGov'
        version = 'v1.0'
      } else {
        name = 'PermitToken'
        version = '1.0'
      }

      let _amount = String(amount).replace(',', '')

      const amountIn = new BigNumber(_amount).times(`1e${fromToken.decimals}`)
      const hexAmountIn = '0x' + amountIn.toString(16)
      const amountOutMin = 0
      const nonce = await token.nonces(account)
      const hexNonce = new BigNumber(nonce.toString()).toNumber()
      const deadline = Math.floor(Date.now() / 1000) + 999

      let signature = await getSignature(
        name,
        version,
        signer,
        tokenIn,
        account,
        routerAddr,
        hexAmountIn,
        hexNonce,
        deadline,
        chainId,
      )

      // console.log({ name, version, chainId, verifyingAddress: tokenIn })
      // console.log({ owner: account, spender: routerAddr, amount: hexAmountIn, nonce: hexNonce, deadline: deadline })
      // console.log('signature', signature)

      const res = await swap(apiBase, {
        account,
        amountIn,
        amountOutMin,
        deadline,
        signature,
        routerAddr,
        tokenIn,
        path,
      })

      // console.log(res)

      if (res.error) {
        commit('setLoading', false)
        return res
      } else {
        const hash = await res.receipt.hash
        console.log('swap hash', hash)
        const tx = await web3Provider.waitForTransaction(hash)
        console.log('tx', tx)
        commit('setLoading', false)
        return tx
      }

      // let receipt = await router.swapExactTokensForTokens(
      //   account,
      //   amountIn,
      //   amountOutMin,
      //   deadline,
      //   signature,
      // )

      // console.log(receipt)

      // const res = await receipt.wait()

      // console.log(res)
      // return res
    } catch (e) {
      commit('setLoading', false)
      return {
        error: true,
        message: e.message,
      }
    }
  },
  async pilotCalc({ rootState, commit }, { amountIn, amountOut, fromToken }) {
    if (amountIn && amountOut) {
      return
    }
    try {
      commit('setPilotLoading', true)
      const { routerAddr, routes } = rootState.wallet.currentNetwork
      const { web3Provider } = rootState.wallet
      const path = routes[fromToken.symbol.toLowerCase()].split(',')
      // console.log('routerAddr', routerAddr)
      const routerContract = new ethers.Contract(routerAddr, routerPermitAbi, web3Provider)
      if (amountIn) {
        let _amount = String(amountIn).replace(',', '')
        const _amountIn = new BigNumber(_amount).times(`1e${fromToken.decimals}`).toFixed()
        // console.log('fromToken.address', fromToken.address)
        const res = await routerContract.getAmountsOut(_amountIn, fromToken.address, path)
        return new BigNumber(String(res[1]))
          .div(UNIT_WEI)
          .times(1 - gasSwapFee)
          .toFixed(2)
      }

      // if (amountOut) {
      //   let _amount = String(amountOut).replace(',', '')
      //   const _amountOut = new BigNumber(_amount)
      //     .div(String(1 - gasSwapFee))
      //     .times(UNIT_WEI)
      //     .toFixed(0)
      //   const res = await routerContract.getAmountsIn(_amountOut)
      //   return new BigNumber(String(res[0])).div(UNIT_WEI).toFixed(2)
      // }
    } catch (e) {
      console.log(e.message)
      commit('setPilotLoading', false)
    } finally {
      commit('setPilotLoading', false)
    }
  },
}

export const swapGas = { namespaced, state, getters, mutations, actions }
