import { ethers } from 'ethers'

import erc20 from '@/constants/erc20.json'
import erc721 from '@/constants/erc721.json'
import erc1155 from '@/constants/erc1155.json'

const namespaced = true

const state = {
  txLoading: {},
  txHashs: {},
}

const getters = {}

const mutations = {
  setTxLoading(state, { symbol, status }) {
    state.txLoading = Object.assign({}, { ...state.txLoading }, { [symbol]: status })
  },
  setTxHashs(state, { symbol, txHash }) {
    state.txHashs = Object.assign({}, { ...state.txHashs }, { [symbol]: txHash })
  },
}

const actions = {
  async send({ rootState, commit, dispatch }, { receiverAddress, balance, token }) {
    commit('setTxLoading', { symbol: token.symbol, status: true })

    const signer = rootState.wallet.signer
    const account = rootState.wallet.account

    if (token.native) {
      const nonce = await signer.getTransactionCount('latest')
      const amount = ethers.utils.parseEther(balance)

      let txRes

      try {
        const tx = await signer.sendTransaction({
          from: account,
          to: receiverAddress,
          value: amount,
          nonce,
        })
        console.log('Sent native token TX: ', tx)
        commit('setTxHashs', { symbol: token.symbol, txHash: tx.hash })

        txRes = await tx.wait()
      } catch (error) {
        console.log('An error occur: ', error)
        commit('setTxLoading', { symbol: token.symbol, status: false })
      }

      if (txRes && txRes.status === 1) {
        console.log(`Transfer ${balance} from ${txRes.from} to ${txRes.to} succeed`)
        // await dispatch('token/updateToken', { symbol: token.symbol }, { root: true })
      }

      commit('setTxLoading', { symbol: token.symbol, status: false })
      commit('setTxHashs', { symbol: token.symbol, txHash: '' })
    } else {
      const contract = new ethers.Contract(token.address, erc20, signer)

      const filter = contract.filters.Transfer(account, receiverAddress)

      contract.once(filter, async (from, to) => {
        console.log(`Transfer ${balance} from ${from} to ${to} succeed`)
        await dispatch('token/updateToken', { symbol: token.symbol }, { root: true })
        commit('setTxLoading', { symbol: token.symbol, status: false })
        commit('setTxHashs', { symbol: token.symbol, txHash: '' })
      })

      const amount = ethers.utils.parseUnits(balance, token.decimals)

      try {
        const transferTx = await contract.transfer(receiverAddress, amount)
        console.log('transferTx: ', transferTx)
        commit('setTxHashs', { symbol: token.symbol, txHash: transferTx.hash })
      } catch (error) {
        console.log('An error occur: ', error)
        commit('setTxLoading', { symbol: token.symbol, status: false })
      }
    }
  },
  async sendNFT({ rootState, commit, dispatch }, { receiverAddress, token, nft, balance }) {
    commit('setTxLoading', { symbol: token.tokenSymbol, status: true })

    const abi = token.type === 'ERC721' ? erc721 : erc1155

    const signer = rootState.wallet.signer
    const account = rootState.wallet.account

    const contract = new ethers.Contract(token.address, abi, signer)

    try {
      let transferTx

      if (token.type === 'ERC721') {
        transferTx = await contract.transferFrom(account, receiverAddress, nft.id)
      } else {
        transferTx = await contract.safeTransferFrom(account, receiverAddress, nft.id, balance, '0x')
      }

      console.log('transferTx: ', transferTx)
      commit('setTxHashs', { symbol: token.tokenSymbol, txHash: transferTx.hash })

      await transferTx.wait()

      commit('setTxLoading', { symbol: token.tokenSymbol, status: false })
      commit('setTxHashs', { symbol: token.tokenSymbol, txHash: '' })

      return true

    } catch (error) {
      console.log('An error occur: ', error)
      commit('setTxLoading', { symbol: token.tokenSymbol, status: false })
      return false
    }

  }
}

export const tx = { namespaced, state, getters, mutations, actions }
