export const passportMainPreUrl = 'https://passport.meter.io/#/'
export const passportTestPreUrl = 'https://passport-test.meter.io/#/'
export const scanMainPreUrl = 'https://scan.meter.io'
export const meterStakingUrl = 'https://staking.meter.io/'
export const fiatToMeterUrl = 'https://pay.c14.money/?clientId=6db85fa1-7324-402f-9109-2ed9deab03c6&targetAssetId=cce88109-9347-4f99-b28c-7592d741c46f'

export const UNIT_WEI = 1e18

export const supportGasSwapNet = [82]
export const gasSwapFee = 0.03 // means 3%
export const feePerTx = 0.0105

export const mainnet = [1, 82, 56, 1284, 1285, 43114, 361, 333999, 246, 137]

export const TWEET_CONTENT = 'Just claimed my free Sumerian ID and Sumerian Purse at https://wallet.meter.io  You could now send funds on @Meter_IO to any twitter users.  Only the receipient will be able to unlock their purse.'

export const supportNetworkList = [
  {
    networkId: 1,
    name: 'Ethereum',
    nativeTokenName: 'ETH',
    nativeTokenSymbol: 'ETH',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://mainnet.infura.io/v3/8467c23f8927474c92f7e95e32aa721b',
    blockExplorer: 'https://etherscan.io',
    mode: 'mainnet',
    multicallAddr: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  },
  {
    networkId: 82,
    name: 'Meter',
    nativeTokenName: 'MTR',
    nativeTokenSymbol: 'MTR',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://rpc.meter.io',
    blockExplorer: 'https://scan.meter.io',
    mode: 'mainnet',
    // for gasless swap
    // routerAddr: '0x201bd6df90c2b06674c492a29ebeabb5a4f5cd4e', // for mtrg => mtr
    // routerAddr2: '0x128d0367eeb352d143ce77d720b4e5caa43b131a',  // for eth => mtr
    // routerAddrs: {
    //   mtrg: '0x201bd6df90c2b06674c492a29ebeabb5a4f5cd4e',
    //   eth: '0x128d0367eeb352d143ce77d720b4e5caa43b131a',
    //   bnb: '0x30ce2904dbcc49a940bca362c06f17062c04cb38'
    // },
    routerAddr: '0xb1fa6159dc42fe5281d68502944b17d3929dbebe',
    routes: {
      mtrg: '0xac4c5aa6983ca82b552388b1a889509af746e5c3,0xacf06e5c551dd9c7981b7f107d8eda6b6f8bcaa1',  // mtrg-suusd,mtr-suusd
      eth: '0x1211650c69649222340672d35c6b8f9aca93564d,0xf803f4432d6b85bc7525f85f7a9cf7398b5ebe7d',  // eth-mtrg,mtrg-mtr
      bnb: '0xa10b1935c3c1f4e74a0c6f0b94fbed8b6562c096,0xf803f4432d6b85bc7525f85f7a9cf7398b5ebe7d',  // mtrg-bnb,mtrg-mtr
      suusd: '0xacf06e5c551dd9c7981b7f107d8eda6b6f8bcaa1', // mtr-suusd
      'usdc.eth': '0x2e01fd91e5a56665c9836da2f8fad62d80857b41,0xacf06e5c551dd9c7981b7f107d8eda6b6f8bcaa1'  // usdc.eth-suusd,suusd-mtr
    },
    apiBase: 'https://api.meter.io:8000/api/',
    // apiBase: 'https://api.meter.io:12001/api/',
    // apiBase: 'http://builder.meter.io:3000/api/',
    multicallAddr: '0x99D510753552698d13D28c3B2042A37Ac6F9E38C',
    wstMTRG: '0xE2dE616fBD8cb9180B26FcFB1B761A232FE56717',
  },
  {
    networkId: 56,
    name: 'BNB Chain',
    nativeTokenName: 'BNB',
    nativeTokenSymbol: 'BNB',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://bsc-dataseed.binance.org',
    blockExplorer: 'https://bscscan.com',
    mode: 'mainnet',
    multicallAddr: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  },
  {
    networkId: 1285,
    name: 'Moonriver',
    nativeTokenName: 'MOVR',
    nativeTokenSymbol: 'MOVR',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://rpc.moonriver.moonbeam.network',
    blockExplorer: 'https://blockscout.moonriver.moonbeam.network/',
    mode: 'mainnet',
    multicallAddr: '0xaeF00A0Cf402D9DEdd54092D9cA179Be6F9E5cE3',
  },
  {
    networkId: 43114,
    name: 'Avalanche',
    nativeTokenName: 'AVAX',
    nativeTokenSymbol: 'AVAX',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    blockExplorer: 'https://cchain.explorer.avax.network',
    mode: 'mainnet',
    multicallAddr: '0x84514BeaaF8f9a4cbe25A9C5a7EBdd16B4FE7154',
  },
  {
    networkId: 361,
    name: 'Theta',
    nativeTokenName: 'TFUEL',
    nativeTokenSymbol: 'TFUEL',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://eth-rpc-api.thetatoken.org/rpc',
    blockExplorer: 'https://explorer.thetatoken.org',
    mode: 'mainnet',
    multicallAddr: '0xB48BbAD564Ceb6fB30cCea2Af248ccF6398aEab5',
  },
  // {
  //   networkId: 333999,
  //   name: 'Polis',
  //   nativeTokenName: 'POLIS',
  //   nativeTokenSymbol: 'POLIS',
  //   nativeTokenDecimals: 18,
  //   rpcUrl: 'https://rpc.polis.tech',
  //   blockExplorer: 'https://explorer.polis.tech',
  //   mode: 'mainnet',
  // },
  // {
  //   networkId: 246,
  //   name: 'EnergyWeb',
  //   nativeTokenName: 'EWT',
  //   nativeTokenSymbol: 'EWT',
  //   nativeTokenDecimals: 18,
  //   rpcUrl: 'https://rpc.energyweb.org',
  //   blockExplorer: 'https://explorer.energyweb.org',
  //   mode: 'mainnet',
  // },
  {
    networkId: 1284,
    name: 'Moonbeam',
    nativeTokenName: 'GLMR',
    nativeTokenSymbol: 'GLMR',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://rpc.api.moonbeam.network',
    blockExplorer: 'https://blockscout.moonbeam.network',
    mode: 'mainnet',
    multicallAddr: '0xA63dc61430B5b2c1C18D39d753b49C12C2AA9091',
  },
  {
    networkId: 137,
    name: 'Polygon',
    nativeTokenName: 'MATIC',
    nativeTokenSymbol: 'MATIC',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://polygon-rpc.com',
    blockExplorer: 'https://polygonscan.com',
    mode: 'mainnet',
    multicallAddr: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d',
  },
  {
    networkId: 42161,
    name: 'Arbitrum',
    nativeTokenName: 'ETH',
    nativeTokenSymbol: 'ETH',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://arb1.arbitrum.io/rpc',
    blockExplorer: 'https://arbiscan.io',
    mode: 'mainnet',
    multicallAddr: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
  },
  {
    networkId: 8453,
    name: 'Base',
    nativeTokenName: 'ETH',
    nativeTokenSymbol: 'ETH',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://mainnet.base.org',
    blockExplorer: 'https://basescan.org',
    mode: 'mainnet',
    multicallAddr: '0x96fFa1203e4479f6656A21E5f00eEc284daD7141',
  },
  {
    networkId: 83,
    name: 'Meter Testnet',
    nativeTokenName: 'MTR',
    nativeTokenSymbol: 'MTR',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://rpctest.meter.io',
    blockExplorer: 'https://scan-warringstakes.meter.io',
    mode: 'testnet',
    // for gasless swap
    routerAddr: '0xeB4c6D1287c4c40c7D111130ac3cdC8FbAFEcD71',
    // twitter nft wallet contract
    nftWallet: '0xddaefa94207e0df66e029ae175736c984ee83d85',
    // twitter nft contract
    twitterContract: '0x81d6e72b9cdcc865ec5f2ce64e24d0d92661f9e6',
    apiBase: 'https://api.meter.io:4000/api/',
    // apiBase: 'http://builder.meter.io:3000/api/',
    multicallAddr: '0x5EAFE862c041404a7139DC9eEA43883230dCF6D9'
  },
  {
    networkId: 97,
    name: 'BSC Testnet',
    nativeTokenName: 'BNB',
    nativeTokenSymbol: 'BNB',
    nativeTokenDecimals: 18,
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    blockExplorer: 'https://testnet.bscscan.com',
    mode: 'testnet',
  },
]

export const bnfmt1 = {
  prefix: '$',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
}
export const bnfmt2 = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
}

export const regExpList = {
  address: '^0x[A-Za-z0-9]{40}$',
}
