import { ethers } from 'ethers'
import { Contract, Provider, setMulticallAddress } from 'ethers-multicall';
import { BigNumber } from 'bignumber.js'

import erc20 from '@/constants/erc20.json'
import { getWalletTokens, getAllTokenPrice, getSupportNetworkListByMode } from '../../api'
import { bnfmt1, bnfmt2 } from '@/constants'
import wstMTRGABI from '@/constants/wstMTRG.json'

const { VUE_APP_MODE } = process.env

const namespaced = true
const state = {
  renderLoading: false,
  tokens: [],
  balanceGreateThanZeroTokens: [],
  currentSendToken: null,
  tokensPrice: {},

  isSupportNetwork: true,

  showSendModal: false,
  showPassportModal: {
    show: false,
    operate: 'deposit or withdraw',
    resourceId: '',
  },
}

const getters = {}

const mutations = {
  setTokens(state, tokens) {
    const nativeToken = tokens.filter((token) => token.native)
    const notNativeToken = tokens.filter((token) => !token.native)

    const sortedNotNativeToken = notNativeToken.sort((x, y) => {
      if (x._value.isGreaterThan(y._value)) {
        return -1
      } else if (x._value.isLessThan(y._value)) {
        return 1
      } else {
        return 0
      }
    })

    state.tokens = nativeToken.concat(sortedNotNativeToken)
  },
  setRenderLoading(state, renderLoading) {
    state.renderLoading = renderLoading
  },
  setBalanceGreateThanZeroTokens(state, balanceGreateThanZeroTokens) {
    const nativeToken = balanceGreateThanZeroTokens.filter((token) => token.native)
    const notNativeToken = balanceGreateThanZeroTokens.filter((token) => !token.native)

    const sortedNotNativeToken = notNativeToken.sort((x, y) => {
      if (x._value.isGreaterThan(y._value)) {
        return -1
      } else if (x._value.isLessThan(y._value)) {
        return 1
      } else {
        return 0
      }
    })

    state.balanceGreateThanZeroTokens = nativeToken.concat(sortedNotNativeToken)
  },
  setCurrentSendToken(state, symbol) {
    for (const item of state.balanceGreateThanZeroTokens) {
      if (item.symbol === symbol) {
        return (state.currentSendToken = item)
      }
    }
  },

  setShowSendModal(state, symbol) {
    if (!symbol) {
      if (state.balanceGreateThanZeroTokens.length) {
        state.currentSendToken = state.balanceGreateThanZeroTokens[0]
        state.showSendModal = true
      } else {
        state.currentSendToken = null
      }
    } else {
      if (state.balanceGreateThanZeroTokens.length) {
        for (const item of state.balanceGreateThanZeroTokens) {
          if (item.symbol === symbol) {
            state.currentSendToken = item
            return (state.showSendModal = true)
          }
        }

        state.currentSendToken = null
      } else {
        state.currentSendToken = null
      }
    }
  },
  setCloseSendModal(state) {
    state.showSendModal = false
  },
  setIsSupportNetwork(state, isSupportNetwork) {
    state.isSupportNetwork = isSupportNetwork
    if (!isSupportNetwork) {
      state.renderLoading = false
    }
  },
  setTokensPrice(state, prices) {
    state.tokensPrice = prices
  },
  setShowPassportModal(state, { operate = '', resourceId = '' } = {}) {
    state.showPassportModal = {
      show: !state.showPassportModal.show,
      operate,
      resourceId,
    }
  },

  clearRelevantInfo(state) {
    state.renderLoading = false
    state.tokens = []
    state.balanceGreateThanZeroTokens = []
    state.currentSendToken = null
    state.showSendModal = false
  },
}

const actions = {
  async initTokens({ rootState, state, commit, dispatch }) {
    console.log('init tokens data')
    const chainId = rootState.wallet.chainId

    console.log('token VUE_APP_MODE: ', VUE_APP_MODE)
    const supportNetworkList = getSupportNetworkListByMode(VUE_APP_MODE)
    const supportnetworkIdList = supportNetworkList.map((network) => network.networkId)
    if (!supportnetworkIdList.includes(chainId)) {
      commit('setIsSupportNetwork', false)
      commit('clearRelevantInfo')

      return
    } else {
      commit('setIsSupportNetwork', true)
    }
    if (state.renderLoading) {
      return
    }
    commit('setRenderLoading', true)

    const tokens = []
    const coinIds = []

    const walletTokens = await getWalletTokens()

    for (const token of walletTokens.data.tokens) {
      if (Number(chainId) === token.chainId) {
        tokens.push(token)
        if (token.coinId) {
          coinIds.push(token.coinId)
        }
      }
    }

    // get all token price
    const prices = await getAllTokenPrice(coinIds)
    commit('setTokensPrice', prices)

    try {
      await dispatch('getTokenBalance', { tokens })
    } catch (e) {
      console.log('occur some error: ', e)
    } finally {
      commit('setRenderLoading', false)
    }
  },
  async getTokenBalance({ rootState, state, commit, dispatch }, { tokens }) {
    console.log('get tokens balance')
    const { web3Provider, account, chainId, currentNetwork } = rootState.wallet
    const newTokens = []
    const balanceGreateThanZeroTokens = []

    if (currentNetwork.multicallAddr) {
      console.log('use multicall for token balance')
      setMulticallAddress(chainId, currentNetwork.multicallAddr)
      const multicall = new Provider(web3Provider, chainId)
      const requestArr = tokens.map(t => {
        if (t.native) {
          return multicall.getEthBalance(account)
        } else {
          const contract = new Contract(t.address, erc20)
          return contract.balanceOf(account)
        }
      })
      const result = await multicall.all(requestArr)

      for (let i = 0; i < tokens.length; i++) {
        const _balance = new BigNumber(String(result[i])).div(`1e${tokens[i].decimals}`)

        const price = state.tokensPrice[tokens[i].coinId] ? state.tokensPrice[tokens[i].coinId]['usd'] : 0

        let _value = _balance.times(price)
        if (tokens[i].symbol === 'wstMTRG' && currentNetwork.wstMTRG) {
          const wstMTRGContract = new ethers.Contract(currentNetwork.wstMTRG, wstMTRGABI, web3Provider)
          const stMTRGPerToken = await wstMTRGContract.stMTRGPerToken()
          const stMTRGPerTokenDecimals = new BigNumber(String(stMTRGPerToken)).div(1e18)
          _value = _balance.times(price).times(stMTRGPerTokenDecimals)
        }
        
        

        const t = {
          ...tokens[i],
          _balance,
          balance: _balance.toFormat(3, bnfmt2),
          _value,
          value: _value.toFormat(2, bnfmt1),
          price,
        }

        newTokens.push(t)

        if (_balance.isGreaterThan(0)) {
          balanceGreateThanZeroTokens.push(t)
        }

        if (state.currentSendToken && state.currentSendToken.symbol === t.symbol) {
          state.currentSendToken = Object.assign({}, { ...state.currentSendToken }, { ...t })
        }
      }
    } else {
      for (const token of tokens) {
        const _token = await dispatch('getNewToken', { token })
  
        newTokens.push(_token)
  
        if (_token._balance.isGreaterThan(0)) {
          balanceGreateThanZeroTokens.push(_token)
        }
  
        if (state.currentSendToken && state.currentSendToken.symbol === _token.symbol) {
          state.currentSendToken = Object.assign({}, { ...state.currentSendToken }, { ..._token })
        }
      }
    }

    commit('setTokens', newTokens)
    commit('setBalanceGreateThanZeroTokens', balanceGreateThanZeroTokens)
  },
  async updateToken({ state, dispatch }, { symbol }) {
    console.log('update token balance: ', symbol)
    const tokens = state.tokens

    const aimTokenIndex = tokens.findIndex((token) => token.symbol === symbol)
    const aimToken = tokens[aimTokenIndex]

    if (!aimToken) {
      return
    }

    const token = await dispatch('getNewToken', { token: aimToken })
    console.log('update token: ', token)

    state.tokens.splice(aimTokenIndex, 1, token)

    state.balanceGreateThanZeroTokens.splice(
      state.balanceGreateThanZeroTokens.findIndex((t) => t.symbol === symbol),
      1,
      token,
    )

    if (state.currentSendToken && state.currentSendToken.symbol === symbol) {
      state.currentSendToken = Object.assign({}, { ...state.currentSendToken }, { ...token })
    }
  },
  async getNewToken({ rootState, state }, { token }) {
    const web3Provider = rootState.wallet.web3Provider
    const signer = rootState.wallet.signer
    const account = rootState.wallet.account

    let balance = 0
    if (token.native) {
      balance = await signer.getBalance(ethers.providers.balance)
    } else {
      const contract = new ethers.Contract(token.address, erc20, web3Provider)
      balance = await contract.balanceOf(account)
    }

    const _balance = new BigNumber(String(balance)).div(`1e${token.decimals}`)

    const price = state.tokensPrice[token.coinId] ? state.tokensPrice[token.coinId]['usd'] : 0
    const _value = _balance.times(price)

    return {
      ...token,
      _balance,
      balance: _balance.toFormat(3, bnfmt2),
      _value,
      value: _value.toFormat(2, bnfmt1),
      price,
    }
  },
}

export const token = { namespaced, state, getters, mutations, actions }
