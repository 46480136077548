<template>
  <component :is="name" :height="size" :width="size" />
</template>

<script>
import {
  ArrowDownIcon,
  RepeatIcon,
  CreditCardIcon,
  BoxIcon,
  ChevronDownIcon
} from 'vue-feather-icons'
export default {
  components: {
    ArrowDownIcon,
    RepeatIcon,
    CreditCardIcon,
    BoxIcon,
    ChevronDownIcon
  },
  name: 'FeatherIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    small: { type: Boolean },
    large: { type: Boolean },
  },
  computed: {
    size() {
      if (this.small) {
        return '16px'
      }
      if (this.large) {
        return '28px'
      }
      return '22px'
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
