<template>
  <div class="logo d-flex justify-content-between align-items-center" @click="goWalletPage">
    <img class="logo-img" src="/logo.png" alt="">
    <!-- <h1 class="logo-name text-nowrap font-xlarge-size font-weight-bold ml-2">Meter Passport Wallet</h1> -->
  </div>
</template>

<script>
export default {
  name: "Logo",
  methods: {
    goWalletPage() {
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
          query: {
            ...this.$route.query
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo {
    cursor: pointer;
    .logo-img {
      // width: 40px;
      height: 45px;
    }
  }
</style>